<div class="modal-header">
  <h4 class="modal-title">{{ modalTitle }}</h4>
</div>
<div class="modal-body">
  <p>{{ modalBody }}</p>
</div>
<div class="modal-footer">
  <button type="button"
          class="btn btn-primary"
          *ngIf="modalConfirmation"
          (click)="closeModal(true)">
    {{ modalConfirmation }}
  </button>
  <button type="button"
          *ngIf="modalCancel"
          class="btn btn-outline-dark"
          (click)="closeModal(false)">
    {{ modalCancel }}
  </button>
</div>
