import { Component, Input } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent {
  @Input() modalTitle!: string;
  @Input() modalBody!: string;
  @Input() modalConfirmation!: string;
  @Input() modalCancel!: string;

  constructor(private activeModal: NgbActiveModal) {
  }

  closeModal(confirmation: boolean): void {
    this.activeModal.close(confirmation);
  }
}
